<template>
    <div class="calendar-container">
        <div class="calendar-background" @click="toggleCalendar(false)"></div>
        <div v-on-click-outside="() => toggleCalendar(false)">
            <VDatePicker
                :id="`${name}-datepicker`"
                v-model="dateVal"
                tabindex="0"
                mode="date"
                expanded
                color="primary"
                :popover="false"
                :class="anchor"
                :columns="columnCount"
                :min-page="minPage"
                :min-date="new Date(minDate)"
                :max-date="new Date(new Date().setMonth(new Date().getMonth() + 12))"
                :locale="{
                    id: localeObj.iso,
                    firstDayOfWeek: 1,
                    masks: { weekdays: 'WW', input: 'WWW, DD/MM/YYYY' },
                }"
            >
                <template #footer>
                    <div class="calendar-header text-center">{{ calendarHeading }}</div>
                </template>
            </VDatePicker>
        </div>
    </div>
</template>

<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components';

const VDatePicker = defineAsyncComponent({
    loader: async () => (await import('@angelblanco/v-calendar')).DatePicker,
});

// Calendar Styles
import '@angelblanco/v-calendar/style.css';

const props: any = defineProps({
    name: { type: String, required: true },
    value: { type: Object, required: false, default: null },
    label: { type: Object, required: false, default: () => ({}) },
    calendarHeading: { type: String, required: false, default: 'Please select a date' },
    placeholder: { type: String, required: false, default: 'Select Date' },
    datePickerTitle: { type: String, required: false, default: 'Please select a date' },
    tooltip: { type: String, required: false, default: '' },
    anchor: { type: String, required: false, default: 'left' },
    validation: { type: Object, required: false, default: null },
    minDate: { type: Date, required: false, default: null },
    allowSingleRange: { type: Boolean, required: false, default: false },
});

// Constant Variable for access to locale & translations
const { locales, locale } = useI18n();

onMounted(() => {
    setTimeout(() => document.getElementById(`${props.name}-datepicker`)?.focus(), 100);
});

const localeObj: any = Object.values(locales.value).find((item: any) => item.code === locale.value);
const columnCount = ref(2);
const minPage: object = {
    month: new Date(new Date().setMonth(new Date().getMonth())).getMonth(),
    year: new Date().getFullYear(),
};

// Constant variable for initialising emit events
const emit: any = defineEmits(['update:value', 'toggle-calendar']);

// Whenever new date is set, we want to update locale date and emit the data
// to parent where it will set the new minimum date when required.
// We then close dropdown as it is no longer needed.
const dateVal: any = computed({
    get(): any {
        return props.value;
    },
    set(value: any): any {
        emit('update:value', value);
        toggleCalendar(false);
        return value;
    },
});

const toggleCalendar = (val: boolean) => {
    setTimeout(() => emit('toggle-calendar', val), 200);
};

// Change column count from 1 (mobile) to 2 (desktop/tablet)
function onWidthChange() {
    if (768 < window.innerWidth) {
        columnCount.value = props.allowSingleRange ? 1 : 2;
    } else {
        columnCount.value = 1;
    }
}

if (!import.meta.server) {
    onMounted(() => {
        // Initialize Column Count
        onWidthChange();

        // Watch for column count
        window.addEventListener('resize', onWidthChange);
    });
    onUnmounted(() => window.removeEventListener('resize', onWidthChange));
}
</script>

<style lang="postcss" scoped>
.calendar-container {
    @apply absolute top-20 z-10;

    @media (max-width: theme('screens.md')) {
        @apply fixed bottom-0 top-auto left-0 right-0;

        .calendar-background {
            @apply fixed top-0 left-0 right-0 bottom-0 bg-gray-900/50 z-[99];
        }

        .input-group-container {
            @apply hidden;
        }

        .close {
            @apply fixed block left-4 top-4 text-white;
        }

        & + :deep(.vc-popover-content-wrapper) {
            @apply !fixed !top-[5rem] !left-0 !right-0 !bottom-0 !transform bg-white z-[101];

            .vc-popover-content {
                @apply !mt-0 rounded-none shadow-none border-b-0;
            }

            .vc-container {
                @apply z-[101];
            }

            .calendar-header {
                @apply hidden;
            }

            .vc-pane-header-wrapper {
                @apply top-0;
            }

            .vc-weeks {
                @apply pt-4;
            }

            .vc-day,
            .vc-day-content,
            .vc-highlight {
                @apply min-w-full;
                min-height: calc(100vw / 8);
            }
        }

        :deep(.vc-container) {
            box-shadow: 0px -20px 50px -12px rgb(0 0 0 / 0.25);
        }
    }

    :deep(.vc-container) {
        @apply z-[99] flex flex-col min-w-full max-w-[100vw] shadow-md;
    }

    :deep(.vc-pane-container) {
        @apply flex flex-col-reverse;

        .calendar-header {
            @apply py-2 mx-4 bg-transparent text-primary-500 font-semibold border-b border-gray-200 text-lg text-center;
        }

        .vc-day,
        .vc-day-content,
        .vc-highlight {
            @apply min-h-[40px] min-w-[40px] rounded-none;
        }
        .vc-highlights + .vc-day-content {
            @apply font-normal;
        }
    }

    :deep(.vc-popover-content-wrapper) {
        @apply z-[99];
    }

    :deep(.vc-popover-content) {
        @apply overflow-hidden;

        &.direction-bottom {
            @apply md:mt-1;
        }
    }

    :deep(.vc-popover-caret) {
        @apply hidden;
    }

    :deep(.vc-primary) {
        --vc-accent-50: theme('colors.primary.50');
        --vc-accent-100: theme('colors.primary.100');
        --vc-accent-200: theme('colors.primary.200');
        --vc-accent-300: theme('colors.primary.300');
        --vc-accent-400: theme('colors.primary.400');
        --vc-accent-500: theme('colors.primary.500');
        --vc-accent-600: theme('colors.primary.600');
        --vc-accent-700: theme('colors.primary.700');
        --vc-accent-800: theme('colors.primary.800');
        --vc-accent-900: theme('colors.primary.900');
    }
}
</style>
